<template>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "SpinLoading",
  mounted() {},
};
</script>

<style></style>
