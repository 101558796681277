<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      :data-target="'#ModalEdit' + id"
    >
      <i class="fas fa-edit"></i>
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      :id="'ModalEdit' + id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Rujukan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="text-left">
              <fieldset disabled>
                <div class="form-group">
                  <label for="disabledTextInput">Id</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Disabled input"
                    v-model="this.info.id"
                  />
                </div>
              </fieldset>
              <div class="form-group">
                <label for="formGroupExampleInput">Nama</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="nama"
                />
              </div>
              <div class="form-group">
                <label for="example-date-input2">Date</label>
                <input
                  class="form-control"
                  type="date"
                  v-model="tanggalLahir"
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Tujuan Rujukan</label>
                <select class="form-control" v-model="tujuanRujukan">
                  <option
                    v-for="rujuk in listRujukans"
                    :key="rujuk.id"
                    :value="rujuk"
                    ><h6>{{ rujuk.nama }}</h6></option
                  >
                </select>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Telephone</label>
                <input
                  type="Number"
                  class="form-control"
                  placeholder=""
                  v-model="telepon"
                />
              </div>
              <div class="form-group">
                <label for="formGroupExampleInput">Alamat Pasien</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="alamat"
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Jenis Penjamin</label>
                <select class="form-control" v-model="jenisPenjamin">
                  <option
                    v-for="penja in listPenjamins"
                    :key="penja.id"
                    :value="penja"
                    >{{ penja.nama }}</option
                  >
                </select>
              </div>
              <div class="form-group">
                <label for="formGroupExampleInput2">Daignosa / Keluhan</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Another input"
                  v-model="diagnosa"
                />
              </div>
              <div class="form-group">
                <label for="">Catatan</label>
                <textarea
                  name=""
                  rows="4"
                  cols="55"
                  style="margin: 4px;"
                  v-model="catatan"
                >
                </textarea>
              </div>
              <div class="form-group"></div>
            </form>
            <FileUpload
              name="demo[]"
              :url="fileUploadUrl"
              :showUploadButton="false"
              :multiple="true"
              :auto="true"
              :customUpload="true"
              @clear="clear"
              @select="select"
              @change="onFileChange()"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" data-dismiss="modal" class="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileUpload from "primevue/fileupload";
export default {
  name: "ModalEdit",
  props: {
    id: Number,
    info: Object,
  },
  data() {
    return {
      fileUploadUrl: `${process.env.VUE_APP_BASE_URL}/gallery/api/photos`,
      photos: [],
      alamat: "",
      diagnosa: "",
      jenisPenjamin: {},
      nama: "",
      tanggalLahir: "",
      telepon: "",
      tujuanRujukan: {},
      catatan: "",
    };
  },
  components: {
    FileUpload,
  },
  computed: {
    ...mapState({
      listPenjamins: (state) => state.listPenjamins,
      listRujukans: (state) => state.listRujukans,
    }),
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      console.log(files);
      // this.createImage(files[0]);
    },
    select(event) {
      this.photos = event;
      console.log(event, "select");
    },
    clear(event) {
      this.photos = [];
      console.log(event, "clear");
    },
  },
  mounted() {
    this.alamat = this.info.alamat;
    this.diagnosa = this.info.diagnosa;
    this.jenisPenjamin = this.info.jenisPenjamin;
    this.nama = this.info.nama;
    this.tanggalLahir = this.info.tanggalLahir;
    this.telepon = this.info.telepon;
    this.tujuanRujukan = this.info.tujuanRujukan;
    this.catatan = this.catatan;
  },
};
</script>

<style></style>
