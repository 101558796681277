<template>
  <div
    class="modal fade"
    :id="'Modal' + id"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ info.nama }}</h5>
          <div class="pull-right ">
            <i
              :class="`fas fa-2x fa-check-circle ${iconColor(info.status)}`"
              v-if="info.status !== null"
            ></i>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <!-- {{jejak}} -->
          <div class="ml-3">
            <div class="row mt-1">
              <i class="far fa-notes-medical mr-2"></i>
              <h6>{{ info.diagnosa }}</h6>
            </div>
            <div class="row mt-1">
              <i class="far fa-disease mr-2"></i>
              <h6>
                <a
                  href=""
                  data-toggle="modal"
                  :data-target="'#Modal' + info.id"
                  >{{ getEnum(info.jejakTerakhirStage) }}</a
                >
              </h6>
            </div>
            <div class="row mt-1">
              <i class="far fa-stopwatch mr-2"></i>
              <h6>{{ info.jejakTerakhirTimestamp | timeChange }}</h6>
            </div>
          </div>

          <hr />

          <div class=" text-left">
            <h6>Status Tracking</h6>
            <Timeline :value="TimelineData">
              <template #opposite="slotProps">
                <small class="p-text-secondary">{{
                  slotProps.item.date
                }}</small>
              </template>
              <template #content="slotProps">
                {{ slotProps.item.status }}
              </template>
            </Timeline>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Timeline from 'timeline-vuejs'
import Timeline from "primevue/timeline";
export default {
  props: {
    id: Number,
    info: Object,
  },
  components: {
    Timeline,
  },
  data() {
    return {
      events: [
        {
          status: "Ordered",
          date: "15/10/2020 10:30",
          icon: "pi pi-shopping-cart",
          color: "#9C27B0",
          image: "game-controller.jpg",
        },
        {
          status: "Processing",
          date: "15/10/2020 14:00",
          icon: "pi pi-cog",
          color: "#673AB7",
        },
        {
          status: "Shipped",
          date: "15/10/2020 16:15",
          icon: "pi pi-shopping-cart",
          color: "#FF9800",
        },
        {
          status: "Delivered",
          date: "16/10/2020 10:00",
          icon: "pi pi-check",
          color: "#607D8B",
        },
      ],
      events2: ["2020", "2021", "2022", "2023"],
    };
  },
  computed: {
    TimelineData() {
      const map1 = this.info.jejaks.map((x) => {
        let date = new Date(x.timestamp);
        let str = x.enumStage;
        return {
          date: date.toLocaleString(),
          status: this.getEnum(str),
          // "description": `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          // "showDayAndMonth": true,
        };
      });

      return map1;
    },
  },
  filters: {
    timeChange(val) {
      var a = new Date(val)
      return a.toLocaleString( 'hi-IN' );
    }
  },
  methods: {
    getEnum(val) {
      switch (val) {
        case "PASIEN_BELUM_MASUK":
          return "Pasien Belum Masuk";
        case "PASIEN_DATANG":
          return "Pasien Datang";
        case "DIRUJUK_KETEMPAT_LAIN":
          return "Dirujuk Ketempat Lain";
        case "DALAM_PERAWATAN":
          return "Dalam Perawatan";
        case "PASIEN_PULANG":
          return "Pasien Pulang";
        case "TIDAK_JADI_DATANG":
          return "Tidak Jadi Datang";
        case "DALAM_PROSES_PEMBAYARAN":
          return "Pasien Belum Masuk";
        case "SUDAH_TERBAYARKAN":
          return "Pasien Belum Masuk";
        case "CLOSED":
          return "Closed";
      }
    },
    iconColor(status) {
      switch (status) {
        case 1:
          return "text-danger";
          break;
        case 2:
          return "text-warning";
          break;
        case 3:
          return "text-success";
          break;
      }
    },
  },
};
</script>

<style>
.p-timeline-event-marker {
  /* color: red; */
  border-radius: 50px;
  /* height: 10px; */
  background-color: white;

  border: 1px solid blue;
  margin-top: 1px;
}

.p-timeline-event-connector {
  /* background-color: black; */
  /* color: black; */
  border-left: 0.5px solid black;
}
</style>
