<template>
  <div>
    <div class="container-fluid mt-5 pt-3 pl-5 pr-5">
      <div class="text-left ml-3">
        <h2>Daftar Rujukan</h2>
      </div>
      <div class="mb-3 d-flex justify-content-end" id="buttonsTop">
        <div class="dropdown mr-3">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Update Terakhir
          </button>
          <div class="btn-group">
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <h6 class="ml-2">Urut Berdasarkan</h6>
              <label class="dropdown-item"
                ><input
                  type="radio"
                  name="customRadio1"
                  value="jejakTerakhirTimestamp"
                  v-model="getUrut"
                />&nbsp; Update Terakhir</label
              >
              <!-- <a class="dropdown-item"><input type="checkbox" name="checkbox" value="nama" v-model="getUrut">&nbsp; Nama</a> -->
              <label class="dropdown-item"
                ><input
                  type="radio"
                  name="customRadio2"
                  value="diagnosa"
                  v-model="getUrut"
                />&nbsp; Diagnosa</label
              >
              <!-- <a class="dropdown-item" href="#"><input type="checkbox" name="checkbox" value="ini 3" v-model="getUrut">&nbsp; Keluhan</a> -->

              <div class="dropdown-divider"></div>
              <h6 class="ml-2">Arah Urutan</h6>
              <label class="dropdown-item"
                ><input
                  type="radio"
                  name="customRadio"
                  value="desc"
                  v-model="getArah"
                />&nbsp; Descending (turun)</label
              >
              <label class="dropdown-item"
                ><input
                  type="radio"
                  name="customRadio"
                  value="asc"
                  v-model="getArah"
                />&nbsp; ascending (naik)</label
              >
            </div>
          </div>
        </div>

        <div class="dropdown mr-3">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Filter
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="PASIEN_BELUM_MASUK"
                v-model="getFilters"
              />&nbsp; Pasien Belum Masuk</label
            >
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="PASIEN_DATANG"
                v-model="getFilters"
              />&nbsp; Pasien Datang</label
            >
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="DIRUJUK_KETEMPAT_LAIN"
                v-model="getFilters"
              />&nbsp; Dirujuk Ketempat Lain</label
            >
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="DALAM_PERAWATAN"
                v-model="getFilters"
              />&nbsp; Dalam Perawatan</label
            >
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="PASIEN_PULANG"
                v-model="getFilters"
              />&nbsp; Pasien Pulang</label
            >
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="TIDAK_JADI_DATANG"
                v-model="getFilters"
              />&nbsp; Tidak Jadi Datang</label
            >
            <!-- <a class="dropdown-item"><input type="checkbox" name="checkbox" value="DALAM_PROSES_PEMBAYARAN" v-model="getFilters">&nbsp; Dalam Proses Pembayaran</a>
        <a class="dropdown-item"><input type="checkbox" name="checkbox" value="SUDAH_TERBAYARKAN" v-model="getFilters">&nbsp; Sudah Terbayarkan</a> -->
            <label class="dropdown-item"
              ><input
                type="checkbox"
                name="checkbox"
                value="CLOSED"
                v-model="getFilters"
              />&nbsp; Closed</label
            >
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="enableModalAdd()"
        >
          Add New
        </button>

        <!-- <button class="btn " >add here</button> -->
      </div>
      <div class="" v-if="loading">
        <div class="row">
          <skleton v-for="item in 3" :key="item" />
        </div>
        <!-- <h1>LOADING..</h1> -->
        <!-- <div class="row">
          
        </div> -->
        <!-- <div class="box"></div> -->
      </div>
      <div class="row" id="firstRow" v-else>
        <CardsReff v-for="reff in referralCards" :key="reff.id" :info="reff" />
      </div>
    </div>

    <vue-fab
      mainBtnColor="blue"
      icon="dehaze"
      fabItemAnimate="default"
      id="floatButton"
      :scrollAutoHide="false"
      :hidden="false"
      @clickItem="clickItem('test')"
    >
      <fab-item @clickItem="enableModalAdd()" :idx="0" title="add" icon="add" />
      <fab-item
        @clickItem="enableModalFilter()"
        :idx="1"
        title="filter"
        icon="filter_alt"
      />
      <!-- <fab-item @clickItem="clickItem" :idx="2" title="edit" icon="edit" /> -->
    </vue-fab>

    <div class="d-flex justify-content-center pagi-mobile">
      <div class="col-1" @click.prevent="customPagi('down')">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="col-3 pagi-drop">
        <div class="dropup">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ page }}
          </button>
          <div class="dropdown-menu heigth-max" aria-labelledby="dropdownMenuButton">
            <a
              class="dropdown-item"
              href="#"
              v-for="index in totalPage"
              :key="index"
              @click.prevent="customPagi(index)"
              >{{ index }}</a
            >
          </div>
        </div>
      </div>
      <div class="col-1" @click.prevent="customPagi('up')">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>

    <div class="mt-5 d-flex justify-content-center pagi-web">
      <pagination
        v-model="page"
        :records="totalRecords"
        :perPage="getSize"
        @paginate="clickCallback"
      />

      <div class="dropdown ml-4">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ getSize }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#" @click.prevent="selectSize(10)"
            >15</a
          >
          <a class="dropdown-item" href="#" @click.prevent="selectSize(25)"
            >25</a
          >
          <a class="dropdown-item" href="#" @click.prevent="selectSize(50)"
            >50</a
          >
          <a class="dropdown-item" href="#" @click.prevent="selectSize(100)"
            >100</a
          >
        </div>
      </div>
    </div>

    <!-- <i class="fas fa-sign-out-alt mr-1"></i> -->
    <ModalAdd v-if="isModalAdd" :id="idNEw" />
    <ModalFilter v-if="isModalFilter" />
  </div>
</template>

<script>
// import fabItem from 'vue-float-action-button'
import { mapState } from "vuex";
import Pagination from "vue-pagination-2";
import axios from "@/axios";
import SpinLoading from "../components/SpinLoading";
// import ModalAdd from '../components/ModalAdd'
import CardsReff from "./../components/CardsReff";
import Skleton from "./../components/Skleton.vue";
export default {
  name: "Rujukan",
  data() {
    return {
      menu: [
        {
          id: 1,
          icon: "done",
          title: "good job!",
          color: "#ff9900",
        },
        {
          id: 2,
          icon: "toc",
          title: "test",
          color: "#999",
        },
      ],
      idNEw: 10000,
      mainBtnColor: "#3eaf7c",
      page: 1,
      getSize: 15,
      totalCount: 0,
      // "DIRUJUK_KETEMPAT_LAIN","DALAM_PERAWATAN","TIDAK_JADI_DATANG","PASIEN_DATANG", "PASIEN_PULANG", "DALAM_PROSES_PEMBAYARAN", "SUDAH_TERBAYARKAN"
      parr: "",
    };
  },
  components: {
    Skleton,
    Pagination,
    CardsReff,
    //  ModalAdd: () => import('../components/ModalAdd'),
    ModalAdd: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-reservasi-dokter" */ "./../components/ModalAdd"
            )
          );
        }, 500);
      }),
      loading: SpinLoading,
    }),
    ModalFilter: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-reservasi-dokter" */ "./../components/ModalFilter"
            )
          );
        }, 500);
      }),
      loading: SpinLoading,
    }),
  },
  computed: {
    ...mapState({
      referralCards: (state) => state.referralCards,
      loading: (state) => state.loading,
      isModalAdd: (state) => state.isModalAdd,
      isModalFilter: (state) => state.modalFilter,
      recordsPage: (state) => state.recordsPage,
    }),
    totalRecords() {
      return this.recordsPage;
    },
    totalPage() {
      return Math.ceil(this.recordsPage / this.getSize);
    },
    getFilters: {
      get() {
        return this.$store.state.getFilters
      },
      set(newVal) {
        return this.$store.commit('SET_GET_FILTER', newVal)
      }
    },
    getUrut: {
      get() {
        return this.$store.state.getUrut
      },
      set(newVal) {
        return this.$store.commit('SET_GET_URUT', newVal)
      }
    },
    getArah: {
      get() {
        return this.$store.state.getArah
      },
      set(newVal) {
        return this.$store.commit('SET_GET_ARAH', newVal)
      }
    },
    createParams() {
      var params = new URLSearchParams();
      const map1 = this.getFilters.map((x) => {
        params.append("jejakTerakhirStage.in", x);
      });
      let sort = `${this.getUrut},${this.getArah}`;
      params.append("sort", sort);
      params.append("page", this.page - 1);
      params.append("size", this.getSize);

      return params;
    },
  },
  watch: {
    "$store.state.dummyData": function (val) {
      if (val && val.data) {
        this.$store.commit("SET_REFERRAL_DATA", val);
      } else if (!val.data) {
        this.$store.commit("SET_REFERRAL_DATA", this.$store.state.realData);
      }
    },
    getFilters() {
      this.page = 1;
      this.createParams.set("page", 0);
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
    getArah() {
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
    getUrut() {
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
  },
  methods: {
    customPagi(val) {
      let limit = Math.round(this.recordsPage / this.getSize) + 1;
      let count;
      if (val == "up") {
        count = this.page + 1;
      } else if (val == "down") {
        count = this.page - 1;
      } else {
        count = val;
      }
      if (count > 0 && count < limit) {
        this.page = count;
        this.$store.dispatch("GET_REFERRALS", {
          params: this.createParams,
        });
      }
    },
    selectSize(val) {
      this.getSize = val;
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
    enableModalFilter() {
      this.$store.commit("SET_MODAL_FILTER", true);
    },
    enableModalAdd() {
      this.$store.commit("SET_IS_MODAL_ADD", true);
    },
    clickCallback(pageNum) {
      this.page = pageNum;
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
    clickItem(item) {
      if (item == "add") {
        this.enableModalAdd();
      }
    },
  },
  mounted() {
    $("div.fab-cantainer:not(.fab-item)").bind(
      "DOMSubtreeModified",
      function (obj) {
        var isActive =
          $.grep(obj.target.children, function (n, i) {
            return (
              $(n).hasClass("material-icons") && $(n).hasClass("fab-active")
            );
          }).length > 0;
        if (isActive) $(this).addClass("active");
        else $(this).removeClass("active");
      }
    );

    $("input, label").on("click", function (e) {
      e.stopPropagation();
    });
    this.$store.dispatch("GET_REFERRALS", {
      params: this.createParams,
    });
  },
};
</script>
